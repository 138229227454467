#saleshub-react-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.site-menu-list-item-non-link,
.site-menu-sublist-item-non-link {
  border-right: none;
  border-left: none;
  border-color: transparent;
  background: none;
  cursor: default;
}

.site-menu-list-item-non-link:hover, .site-menu-list-item-non-link:active,
.site-menu-list-item-non-link:focus,.site-menu-sublist-item-non-link:hover, .site-menu-sublist-item-non-link:active,
.site-menu-sublist-item-non-link:focus {
  border-color: transparent;
}

.site-menu-sublist-item-non-link:hover, .site-menu-sublist-item-non-link:active,
.site-menu-sublist-item-non-link:focus {
  color: #4d4f53;;
}

/* .site-menu-admin-only-sublist-item .site-menu-sublist-item-non-link:hover,
.site-menu-admin-only-sublist-item .site-menu-sublist-item-non-link:active,
.site-menu-admin-only-sublist-item .site-menu-sublist-item-non-link:focus { */
.mc-sh-admin-only .site-menu-sublist-item-non-link:hover,
.mc-sh-admin-only .site-menu-sublist-item-non-link:active,
.mc-sh-admin-only .site-menu-sublist-item-non-link:focus {
  color: #b8b8b8;
}

.site-menu-sublist-item {
  font-weight: bold;
}

button.site-menu-sublist-item-non-link {
  font-weight: bold;
  text-transform: none;
  padding: 0;
  letter-spacing: initial;
} 

.site-menu-sub-sublist {
  list-style-type: none;
  padding-inline-start: 20px;
  /* padding-inline-start: 0px; */
}
.site-menu-sub-sublist-item {
  margin: 0 auto 3px;
  font-weight: normal;
}
.site-menu-sub-sublist-item:nth-child(1) {
  margin-top: 3px;
}
.site-menu-list-item-account .site-menu-sublist {
  left: initial;
  right: -5px;
  text-align: right;
  width: 202px;
}
@media (max-width: 1199px) {
  .site-menu-list-item-account .site-menu-sublist {
    left: 0;
    right: initial;
    text-align: left;
    width: 300px;
  }
}